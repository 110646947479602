<template>
  <div>
    <!--로고영역-->
    <ul class="aside_menu">
      <li class="mobile_btn" id="totalmenu">
        <a @click="mobileMenuClick()">{{$t('mobileMenu')}}</a>
      </li>
    </ul>
    <h1 class="logo">
      <a href="/" :tabindex="tabindex+1">{{$t('nationalBdna')}}</a>
    </h1>

    <!-- 메뉴영역 -->
    <div :id="$route.path !='/intro'?'content_menu_navi':'menu_navi'">
      <div class="gnb_bg1"  :style="gnbBgStyle"></div>
      <div id="menu_area">
        <ul @mouseleave="doMouseLeave()">
          <template v-for="(item, index) in depth1List">
            <li v-if="item.hiddenYn != 'Y'" @mouseenter="doMouseEnter()" @focus="doTopLinkFocus(item,index)" @keydown="doKeydownDepth1Link($event, item)" :class="`menu${index+1}`" :key="index" :tabindex="tabindex+2">
              <!-- 1뎁스 -->
              <a @click="depth1Link(item)">
                <span v-html="$sanitize($t(item.mulLangCd))"></span>
              </a>
              <transition name="slide" @after-leave="onAfterLeave">
                <div class="two_depth" v-show="isShow">
                  <ul style="display: block;">
                    <template v-for="(subItem, subIndex) in depth2(item)">
                      <li v-if="subItem.hiddenYn != 'Y'" :key="subIndex">
                        <!-- 2차메뉴 -->
                        <a class="smenu_tit" @click="depth2Link(subItem)" @keydown="doKeydownDepth2Link($event, subItem)" :tabindex="tabindex+2">
                          <span v-html="$sanitize($t(subItem.mulLangCd))"></span>
                          <img src="@/assets/img/skin01/img/common/new_window_icon.gif" v-if="subItem.menuTypeCd=='CMS007.link' && subItem.link.charAt(0) != '/'" :alt="$t('window')" class="ml10">
                        </a>
                        <!-- 3차메뉴 -->
                        <ul style="display: block;" v-if="depth3(subItem).length > 0">
                          <template v-for="(su3Item, sub3Index) in depth3(subItem)">
                            <li v-if="su3Item.hiddenYn != 'Y'" :key="sub3Index">
                              <a  @click="depth3Link(su3Item)" @keydown="doKeydownDepth3Link($event, su3Item)" :tabindex="tabindex+2">
                                <span v-html="$sanitize($t(su3Item.mulLangCd))"></span>
                                <img src="@/assets/img/skin01/img/common/new_window_icon.gif" v-if="su3Item.menuTypeCd=='CMS007.link' && su3Item.link.charAt(0) != '/'" :alt="$t('window')" class="ml10">
                              </a>
                            </li>
                          </template>
                        </ul>
                      </li>
                    </template>
                  </ul>
                </div>
              </transition>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div :class="$route.path !='/intro'?'search_menu' : 'search_menu_main'">
      <ul>
        <li>
          <a href="/eng/" class="lang" target="_blank" @keydown="doKeydownEng()" :tabindex="tabindex+3">
            <span class="eng">EN</span>{{$t('lang')}}
          </a>
        </li>
        <li>
          <a @click="doTopSearch()"  @keydown="doKeydownTopSearch($event)" class="sear" :tabindex="tabindex+4">{{$t('doSearch')}}</a>
        </li>
      </ul>
    </div>
    <div id="tsearch" class="tsearch_w" :style="topSearchStyle">
      <div class="search_wrap1">
        <input v-model="keyword" type="text" @keydown="doSearchFocus($event)" id="top_search" name="search_keyword" class="txt_input search_inp" :placeholder="$t('srchKeyword')" :tabindex="tabindex+4">
        <img src="@/assets/img/sample01/img/common/micon_search_bk.png"  @click="doTotalSearch()" @keydown="doSearchFocus($event)" class="search_btn" :tabindex="tabindex+4">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    depth2() {
      return (depth1) => {
        if(this.depth2List != null) {
          return this.depth2List.filter((e) => {
            return e.seqChar.indexOf(depth1.menuId) > -1
          })
        }else {
          return []
        }
      }
    },
    depth3() {
      return (depth2) => {
        if(this.depth3List != null) {
          return this.depth3List.filter((e) => {
            return e.seqChar.indexOf(depth2.menuId) > -1
          })
        }else{
          return []
        }
      }
    },
    depth4() {
      return (depth3) => {
        if(this.depth4List != null) {
          return this.depth4List.filter((e) => {
            return e.seqChar.indexOf(depth3.menuId) > -1
          })
        }else{
          return []
        }
      }
    }
  },
  created() {
    this.doTopMenuList();
  },
  data() {
    return {
      depth1List: [],
      depth2List: [],
      depth3List: [],
      depth4List: [],
      gnbBgStyle: {display: 'none'},
      isShow: false,
      topSearchStyle: {display: 'none'},
      keyword: null,
    }
  },
  methods: {
    // 전체메뉴 조회
    doTopMenuList() {
      this.$tran('/site/external/main/selectTopMenuListBdna','').then(response => {
        this.depth1List = response.data.depth1List;
        this.depth2List = response.data.depth2List;
        this.depth3List = response.data.depth3List;
        this.depth4List = response.data.depth4List;

        this.$store.dispatch('setDepth1All', this.depth1List);
        this.$store.dispatch('setDepth2All', this.depth2List);
        this.$store.dispatch('setDepth3All', this.depth3List);
        this.$store.dispatch('setDepth4All', this.depth4List);
      })  
    },
    // 1뎁스 링크
    depth1Link(depth1) {
      let menuTypeCd = null;
      const param = {};
      const depth2MenuList = this.depth2(depth1);
      let item = {};

      if(depth2MenuList != null && depth2MenuList.length > 0) {
        const curDepth2 = depth2MenuList[0];
        item = curDepth2;
        let depth3MenuList = this.depth3(curDepth2);
        
        if(depth3MenuList != null && depth3MenuList.length > 0) {
          const curDepth3 = depth3MenuList[0];
          const depth4MenuList = this.depth4(curDepth3);

          if(depth4MenuList != null && depth4MenuList.length > 0) {
            // 4뎁스(menuId: 4뎁스menuId/upmenuId: 3뎁스menuId)
            const curDepth4 = depth4MenuList[0];
            item = curDepth4;
            menuTypeCd = curDepth4.menuTypeCd;
            param.query = {menuId: curDepth4.menuId, upMenuId: curDepth4.upMenuId, s0:curDepth4.firstGrpCd};  
          }else{
            // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
          item = depth3MenuList[0];
          menuTypeCd = curDepth3.menuTypeCd;
          param.query = {menuId: curDepth3.menuId, upMenuId: curDepth3.upMenuId, s0:curDepth3.firstGrpCd};  
          }
        }else{
          // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
          menuTypeCd = curDepth2.menuTypeCd;
          param.query = {menuId: curDepth2.menuId, upMenuId: curDepth2.upMenuId, s0:curDepth2.firstGrpCd}; 
        }
      }else{
        // 1뎁스(menuId: 1뎁스menuId/upmenuId: 1뎁스menuId)
        item = depth1;
        menuTypeCd = depth1.menuTypeCd;
        param.query = {menuId: depth1.menuId, upMenuId: depth1.menuId, s0:depth1.firstGrpCd}; 
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId} 

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param)
      }

      this.doMouseLeave();
    },
    // 2뎁스 링크
    depth2Link(depth2) {
      let menuTypeCd = null;
      const param = {};
      let item = {};

      let depth3MenuList = this.depth3(depth2).filter(e=> e.hiddenYn == 'N');

      if(depth3MenuList != null && depth3MenuList.length > 0) {
        // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
        const curDepth3 = depth3MenuList[0];
        item = depth3MenuList[0];
        menuTypeCd = curDepth3.menuTypeCd;
        param.query = {menuId: curDepth3.menuId, upMenuId: curDepth3.upMenuId, s0:curDepth3.firstGrpCd};  
      }else{
         // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
        menuTypeCd = depth2.menuTypeCd;
        item = depth2;
        param.query = {menuId: depth2.menuId, upMenuId: depth2.upMenuId, s0:depth2.firstGrpCd}; 
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {  
          const contentLink = link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }
      
      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);
      param.params = {isFirst: item.menuId};

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param)
      }

      this.doMouseLeave();
    },
    // 3뎁스 링크
    depth3Link(depth3) {
      let item = {};
      let menuTypeCd = null;
      const param = {};
      const depth4MenuList = this.depth4(depth3);
      
      if(depth4MenuList != null && depth4MenuList.length > 0) {
        const curDepth4 = depth4MenuList[0];
        item = curDepth4; 
        menuTypeCd = curDepth4.menuTypeCd;
        param.query = {menuId: curDepth4.menuId, upMenuId: curDepth4.upMenuId, s0:curDepth4.firstGrpCd};  
      }else{
        item = depth3; 
        menuTypeCd = depth3.menuTypeCd;
        // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
        param.query = {menuId: depth3.menuId, upMenuId: depth3.upMenuId, s0:depth3.firstGrpCd};  
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'content';
      }else if(menuTypeCd == 'CMS007.siteMap') {
        param.name = 'siteMap';
      }else if(menuTypeCd == 'CMS007.dept') {
        param.name = 'dept';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = item.link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부 링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query)
      const nextQuery = JSON.stringify(param.query)
      param.params = {isFirst:item.menuId};

      if(routeQuery != nextQuery || menuTypeCd=='CMS007.link'){
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param);
      }

      this.doMouseLeave();
    },
    doMouseEnter() {
      this.isShow=true;
      this.gnbBgStyle.display='block';
    },
    doMouseLeave() {
      this.isShow=false;
    },
    doTopSearch() {
      const disply = this.topSearchStyle.display;

      if(disply == 'none') {
        this.keyword='';
        this.topSearchStyle.display='block';
      }else{
        this.topSearchStyle.display='none';
      }
    },
    doTotalSearch() {
      if(this.keyword != null && this.keyword != '') {
        this.topSearchStyle.display='none';
        this.$router.push({name:'totalSearch', query: {keyword: this.keyword, menuId: 'M0034', upMenuId: 'M0005'}});
      }
    },
    doSearchFocus(event) {
      if(event.keyCode == 13) {
        this.doTotalSearch();
      }
    },
    onAfterLeave(e) {
      this.gnbBgStyle.display='none';
    },
    mobileMenuClick(){
      this.$emit('onMobileMenu');
    },
    doTopLinkFocus(item, index) {
      this.doMouseEnter(item, index);
    },
    doKeydownDepth1Link(event, item) {
      if(event.keyCode == 13) {
        this.depth1Link(item);
      }
    },
    doKeydownDepth2Link(event, item) {
      if(event.keyCode == 13) {
        this.depth2Link(item);
      }
    },
    doKeydownDepth3Link(event, item) {
      if(event.keyCode == 13) {
        this.depth3Link(item);
      }
    },
    doKeydownTopSearch(event) {
      if(event.keyCode == 13) {
        this.doTopSearch();
      }
    },
    doKeydownEng() {
      this.doMouseLeave();
    }
  }
}
</script>

<style scoped>
.slide-enter-active { 
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: linear;
  -webkit-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}


</style>