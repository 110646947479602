<template>
  <div id="cont_wrap">
    <!-- 게시판 컨텐츠 -->
    <!-- 주요역할 : 게시판분기 -- 게시판 설정 조회 및 전달 -->
    <component v-if="board.boardNo != null && !isLoading" :is="isComponent" :board="board" :grpList="grpList" :depth="depth" :tabindex="tabindex"></component> 
  </div>
</template>

<script>

import dynamicSgl from '@/views_eng/board/dynamicSgl'

export default {
  components: {
    dynamicSgl, // 종합안내도형
  },
  props: {
    //게시판 번호
    contentNo: {
      type: String,
      default: ''
    },
    // 현재 깊이 s?와 연관됨
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  watch: {
    //게시판 번호 변경시 동작
    contentNo(newVal) {
      this.doList(newVal)
    }
  },
  computed: {
    menuId() {
      // 게시판 설정 조회시 사용
      return this.$route.query.menuId
    },
    isComponent(){
      const boardTypeCd = this.board.boardTypeCd;
      
      if(boardTypeCd == 'CMS002.sgl') {
        return 'dynamicSgl' // 종합안내도형
      }
    }
  },
  data() {
    return {
      searchParam: {
        menuId: '',
        contentNo: '',
      },
      board: {
        boardNo: null,
        boardTypeNm: null,
        accNm: null,
        accCd: null,
        boardNm: null,
        boardTypeCd: null,
        grpDispCd: null,
        writeYn:'Y',
        certYn:'N',
        pwYn:'Y',
        grpYn:'N',
        attcYn:'N',
        attcLimitNo:0,
        attcLimitSize:0,
        attcAllowExt: '',
        attcAllowExts: null,
        replyYn:'N',
        commYn:'N',
        ndisYn:'N',
        ndisRequYn: 'N',
        contentYn:'Y',
        editerYn:'N',
        useTeamYn:'N',
        useStDt:'',
        useEnDt:'',
        useTeamOverYn:'N',
        useYn:'Y',
        delFlagYn:'N',
        limitWord:'',
        postTeamYn:'N',
        postDd:0,
        pagePerNo:0,
        newIconDispDd:0,
        noticeYn:'N',
        mainDispYn:'N',
        defContent:'',
        imgWidth: '',
        imgHeight: '',
        imgRowPerCnt: 0,
      },
      //현재 게시판(this.board.boardNo)에 그룹 
      grpList: [],
      isLoading: false,
    }
  },
  created() {
    //게시판 컴포넌트 처음 진입시 동작(watch와는 중복 동작하지 않음)
    this.doList(this.contentNo)
  },
  methods: {
    // 게시판 설정 및 그룹정보 조화
    doList(contentNo) {
      //게시판 번호 널처리
      if(contentNo != null && contentNo != '') {
        //게시판 설정조회 세팅
        this.searchParam.contentNo = contentNo

        //게사판 설정 조회
        if(this.menuId != null && this.menuId != ''){
          this.searchParam.menuId = this.menuId
        }

        this.$tran('/site/external/contents/selectBoardDetailBdnaE', this.searchParam).then(response => {
          this.$set(this, 'board', response.data.board)
          this.$set(this, 'grpList', response.data.grpList)
        }).finally(() => {
          this.isLoading=false
        })
      }
    },
  }
}
</script>
