<template>
  <div>
    <div class="con_tab tab_b"> 
      <ul :class="doLineSplitBtnClass()">
        <template v-for="(item, index) in grpList">
          <li v-if="item.hiddenYn != 'Y'" :class="doBtnActiveClass(item, index)" :tabindex="tabindex" @keydown="doKeydownLink($event, item)" :key="index">
            <a @click="doLinkClick(item)"><span>{{item.grpNm}}</span></a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String
    },
    grpList: {
      type: Array
    },
    //동일 value 허용여부(dynamicDetail의 경우 같은 값인경우 dynamicBoard로 보내기위함)
    isSameAllow: {
      type: Boolean,
      default: false 
    },
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  created() {
    const upMenuId = this.$route.query?.upMenuId;
    const menuId= this.$route.query?.menuId;

    if(upMenuId != null && upMenuId != ''){
      this.upMenuId = upMenuId
    }
    if(menuId != null && menuId != ''){
      this.menuId = menuId
    }

    this.nullIdx = this.grpList.findIndex((e) => {return e.grpCd == null || this.grpList == ''})
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn !="Y"?e+1:0
              },0)
    }
  },
  watch: {
    upMenuId(newVal) {
      if(newVal != null && newVal != ''){
        this.upMenuId = newVal
      } 
    },
    menuId(newVal) {
      if(newVal != null && newVal != ''){
        this.menuId = newVal
      } 
    }
  },  
  data() {
    return {
      menuId: '',
      upMenuId: '',
      activeTab: -1,
      nullIdx: -1,
    }
  },
  methods: {
    // 탭버튼 활성화 클래스 세팅 함수
    doBtnActiveClass(item, index) {
      if((this.value==''?null:this.value) == null && this.nullIdx < 0){
        if(item.grpCd == null || item.grpCd == ''){
          if(this.activeTab != index){
            let key = null;
            if(this.$route.params.isFirst != null && this.$route.params.isFirst != ''){
              key = item.grpCd
            }
            
            if(item.contentType != 'A'){
              this.$emit("changeTab", item.linkUrl , item.contentType, key);
            }else{
              this.$emit("changeTab", item.grpCd , item.contentType, key);
            }

            this.activeTab = index
          }
          return ['on']
        }else{
          return[]
        }
      }else{
        if((this.value==''?null:this.value)== item.grpCd) {
          if(this.activeTab != index){
            if(item.contentType != 'A'){
              this.$emit("changeTab", item.linkUrl , item.contentType, item.grpCd);
            }else{
              this.$emit("changeTab", item.grpCd , item.contentType, item.grpCd);
            }
            this.activeTab = index
          }
          return ['on']
        } else {
          return []
        }
      }
    },
    doLinkClick(item) {
      if(this.isSameAllow){
        const postNo = this.$route.query?.postNo;
        let isSameAllowData = true;

        // 게시물 상세에서만 this.isSameAllow 사용(라우터 중복 오류 남)
        if(!postNo && this.$route.name == 'content') {
          isSameAllowData = false;
        }

        if(item.contentType != 'A') {
          this.$emit("changeTab", item.linkUrl , item.contentType, item.grpCd, isSameAllowData)
        }else{
          this.$emit("changeTab", item.grpCd , item.contentType, item.grpCd, isSameAllowData)
        }
      }

      if(item.grpCd != this.value){
        this.$emit("input", item.grpCd)
      }  
    },
    doLineSplitBtnClass() {
      if(this.grpSize >= 9) {
        return 'no9'
      }else{
        return 'no'+ this.grpSize
      }
    },
    doKeydownLink(event, item) {
      if(event.keyCode == 13) {
        this.doLinkClick(item);
      }
    },
  },
}
</script>
