import Vue from 'vue'
import {setI18n} from '@/libs/i18n'
import axios from 'axios';
import Swal from 'sweetalert2'
import {IsJsonString} from '@/libs/comm/util'
import HDate from '@/libs/date'
import VueCookies from "vue-cookies";
import store from '@/store'

const transaction = axios.create({
  timeout: 60000
});

// 요청 인터셉터 추가
transaction.interceptors.request.use(
  (config) => {
    const siteContext = store.state.siteContext
    if(config.url == '/site/log/check' || config.url == '/bdnae/site/log/check'){
      config.headers['currentUrl'] = window.location.href
      config.headers['currentContext'] = window.location.pathname + window.location.search
      const name = config.data.name
      if(name == null || name == ''){
        config.headers['beforeUrl'] = document.referrer||'directUrl'
      }else{
        config.headers['beforeUrl'] = (window.location.origin + config.data.fullPath)||'directUrl'
      }

      const vCount = VueCookies.get('VCounter_Cookie_'+siteContext)
      if(vCount == null || vCount != 'checked'){
        const hdate = new HDate();
        const toDay = hdate.getMaskFormatString(new Date(), 'yyyyMMdd');
        config.headers['VCounter_Cookie_'+siteContext] = ''
        VueCookies.set('VCounter_Cookie_'+siteContext, 'checked', hdate.strToDate(toDay+'235959', 'yyyyMMddHHmmss'));
      }else{
        config.headers['VCounter_Cookie_'+siteContext] = 'checked'
      }
      
      config.data = {}
    }
    config.headers['Access-Control-Allow-Origin'] = window.origin
    config.headers['Access-Control-Allow-Headers'] = '*'
    config.headers['site-context'] = siteContext
    config.headers['langVersion'] = localStorage.LANG_VERSION
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 추가
transaction.interceptors.response.use(
  (response) => {
    const msgType = {
      S: 'success',
      E: 'error',
      W: 'warning',
      I: 'info',
      Q: 'question',
    };
    const errCode = response.data.code;
    if(response.data instanceof Blob){
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const result = e.target.result
        if(IsJsonString(result)){
          const text = JSON.parse(result);
          const msgCode = 'message.' + text.code.substring(1);
          return setI18n().then((i18n) => {
            return Swal.fire(i18n.t('error'), i18n.t(msgCode), msgType[text.code.substring(0, 1)]).then(() => {
              return Promise.reject(response);
            });
          })
        }
      });
      reader.readAsText(response.data);
    } else {
      if (errCode != null && errCode !== '') {
        const msgCode = 'message.' + errCode.substring(1);
        return setI18n().then((i18n) => {
          return Swal.fire(i18n.t('error'), i18n.t(msgCode), msgType[errCode.substring(0, 1)]).then(() => {
            return Promise.reject(response);
          });
        })
      }
    }
    return response;
  },
  (error) => {
    if (error.response != null) { 
      const errCode = error.response.data.code ?? 'E000009';
      if(errCode != null && errCode != '' && errCode== "S900000"){
        return setI18n().then((i18n) => {
          return Swal.fire(i18n.t('error'), i18n.t("message."+errCode), 'error').then(() => {
            location.href = "/login";
          });
        })
      }else{
        if(errCode == null || errCode == ''){
          errCode = 'E000009';
        }
        return setI18n().then((i18n) => {
          return Swal.fire(i18n.t('error'), i18n.t("message."+errCode), 'error').then(() => {
            return Promise.reject(error);
          });
        })
      }
    }
    return Promise.reject(error);
  },
);

Vue.prototype.$http = transaction

export default transaction
