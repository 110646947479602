import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    depth1All: [],  // 1뎁스 메뉴(전체)
    depth2All: [],  // 2뎁스 메뉴(전체)
    depth3All: [],  // 3뎁스 메뉴(전체)
    depth4All: [],  // 4뎁스 메뉴(전체)
    fpViewing: 0,
    curMenu: '',  //  컨텐츠 상단(cont_head)에 표시될 현재 메뉴이름(mulLangCd)
    isNoPage: false,  // 페이지 존재 여부
    certData: '', // 휴대폰 인증정보
    metaInfo: {}, // 메타태그정보
    siteContext: ''
  },
  getters: {
    depth1All(state) {
      return state.depth1All
    },
    depth2All(state) {
      return state.depth2All
    },
    depth3All(state) {
      return state.depth3All
    },
    depth4All(state) {
      return state.depth4All
    },
    fpViewing(state) {
      return state.fpViewing
    },
    curMenu(state) {
      return state.curMenu
    },
    isNoPage(state) {
      return state.isNoPage
    },
    certData(state) {
      return state.certData
    },
    metaInfo(state) {
      return state.metaInfo
    },
    siteContext(state) {
      return state.siteContext
    },
  },
  mutations: {
    setDepth1All(state, __depth1All){
      state.depth1All = __depth1All
    },
    setDepth2All(state, __depth2All){
      state.depth2All = __depth2All
    },
    setDepth3All(state, __depth3All){
      state.depth3All = __depth3All
    },
    setDepth4All(state, __depth4All){
      state.depth4All = __depth4All
    },
    setFpViewing(state, __fpViewing){
      state.fpViewing = __fpViewing
    },
    setCurMenu(state, __curMenu){
      state.curMenu = __curMenu
    },
    setIsNoPage(state, __isNoPage) {
      state.isNoPage = __isNoPage
    },
    setCertData(state, __certData) {
      state.certData = __certData
    },
    setMetaInfo(state, __metaInfo) {
      state.metaInfo = __metaInfo
    },
    setSiteContext(state, __siteContext) {
      state.siteContext = __siteContext
    },
    
  },
  actions: {
    setDepth1All({commit}, __depth1All){
      return new Promise((resolve, reject) => {
        commit('setDepth1All' , __depth1All);
        resolve(__depth1All);
      });
    },
    setDepth2All({commit}, __depth2All){
      return new Promise((resolve, reject) => {
        commit('setDepth2All' , __depth2All);
        resolve(__depth2All);
      });
    },
    setDepth3All({commit}, __depth3All){
      return new Promise((resolve, reject) => {
        commit('setDepth3All' , __depth3All);
        resolve(__depth3All);
      });
    },
    setDepth4All({commit}, __depth4All){
      return new Promise((resolve, reject) => {
        commit('setDepth4All' , __depth4All);
        resolve(__depth4All);
      });
    },
    setFpViewing({commit}, __fpViewing){
      return new Promise((resolve, reject) => {
        commit('setFpViewing' , __fpViewing);
        resolve(__fpViewing);
      });
    },
    setCurMenu({commit}, __curMenu) {
      return new Promise((resolve, reject) => {
        commit('setCurMenu' , __curMenu);
        resolve(__curMenu);
      });
    },
    setIsNoPage({commit}, __isNoPage) {
      return new Promise((resolve, reject)=> {
        commit('setIsNoPage', __isNoPage);
        resolve(__isNoPage)
      })
    },
    setCertData({commit}, __certData) {
      return new Promise((resolve, reject) => {
        commit('setCertData', __certData);
        resolve(__certData)
      })
    },
    setMetaInfo({commit}, __metaInfo) {
      return new Promise((resolve, reject)=> {
        commit('setMetaInfo', __metaInfo);
        resolve(__metaInfo)
      })
    },
    setSiteContext({commit}, __siteContext) {
      return new Promise((resolve, reject)=> {
        commit('setSiteContext', __siteContext);
        resolve(__siteContext)
      })
    },
  },
  modules: {
  }
})
