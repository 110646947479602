<template>
  <div class="btn_wrap" style="width: 100%;">
    <a v-if="value != null && this.value.length > 0"  :class="classData" @click="openLink(selectedData.url)" @keydown="doKeydownLink($event, selectedData.url)" class="linkButton" :disabled="firstSelected" target="_blank" :tabindex="tabindex">
      <span>{{selectedData.state}}</span>
    </a>
  </div>
</template>

<script>
import {isJson} from "@/libs/comm/util";

export default {
  props: {
    value: {
      type: Array
    },
    fieldData: {
      type: String,
      default: '[]'
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
    }
  },
  computed: {
    selectedData(){
      if(this.value != null && this.value != ''){
        const selectedData = this.value.find((e) => {
          return e.selected =='Y'
        })

        return selectedData
      }
      return {}
    },
    classData(){
      const fieldData = this.fieldData;
      if(fieldData != null){
        if(isJson(fieldData)){

          const originData = JSON.parse(this.fieldData.replaceAll("'",'"'));  
          for(const i in originData){
            const data = originData[i];
            
            if(data.hasOwnProperty('value') && data.value == this.selectedData.state){
              return data.class
            }else if(data.hasOwnProperty('text') && data.text == this.selectedData.state){
              return data.class
            }
          }
        }
      }

      return 'con_sbtn_span span4'
    },
    firstSelected() {
      if(this.value != null && this.value.length > 0){
        return this.value[0].selected == 'Y'  
      }else{
        return false
      }
    }
  },
  methods: {
    openLink(url){
      if(url != null && url != ''){
        window.open(url, '_blank');
      }
    },
    doKeydownLink(event, url) {
      if(event.keyCode == 13) {
        this.openLink(url);
      }
    }
  }
}
</script>