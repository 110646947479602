<template>
  <div id="board" style="width:100%;">
    <!-- 그룹 탭 -->
    <childTab v-if="isTab()" :grpList="grpList" v-model="currentTab" @changeTab="changeTab" :depth="depth" :tabindex="tabindex+1"/>
    <main-content v-if="isContent" :subYn="true" :contents="[{contentNo: subContentNo, contentType: subContentType}]" :grpList="grpList" :depth="depth+1" :tabindex="tabindex+100"/>
    <div v-if="!isContent && !isLoading">
      <!-- 게시물 총 건수 -->
      <div class="board_total" v-if="dataItemList.length > 0">
        <div class="board_total_left">
          <img src="@/assets/img/board/nninc_simple/img/total_ic.gif" alt=""> 
          {{$t('all')}}<strong>{{pagingInfo.totalCnt}}</strong>{{$t('ea')}}({{$t('page')}}<strong class="board_orange">{{searchParam.currentPage}}</strong>/{{pagingInfo.totalPage}})
        </div>
      </div> 
      
      <!-- 리스트 테이블 -->
      <div class="table_blist" id="faq_area">
        <table cellspacing="0" :summary="$t('faqConfirm')" style="border-top: 1px solid #e5e5e5;">
          <caption>{{$t('faq')}}</caption>
          <colgroup>
            <col width="">
          </colgroup>
          <thead>
            <tr>
              <th class="disnone" colspan="1">{{ $t('faq') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="dataItemList.length > 0">
              <template v-for="(item, index) in dataItemList">
                <!-- 질문 -->
                <tr :key="`question${index}`">
                  <td :id="`question_title${index}`" class="title">
                    <img v-if="board.noticeYn == 'Y' && item.noticeDispOver == 'N'" class="pr10" src="@/assets/img/board/nninc_simple/img/notice_ic.gif" :alt="$t('postNotice')">
                    <span class="category_color pl10" v-if="board.grpYn  == 'Y' && item.grpNm != null && item.grpNm != '' && grpSize > 0">[{{item.grpNm}}]</span>
                    <a @click="doDetail(item)" @keydown="doKeydownDetail($event, item)" class="pl5" :tabindex="tabindex+1">
                      {{ item.field03 }}
                      <img v-if="item.newPost == 'Y'" ref="" src="@/assets/img/board/nninc_simple/img/icon_new.gif" :alt="$t('postNew')">
                    </a>
                  </td>
                </tr>
                <!-- 답변 -->
                <tr :key="`answer${index}`" v-show="item.active">
                  <td class="content">
                    <p v-html="$sanitize(decodeHTML(item.field08), boardDefaults)"></p>
                    <fileDownload v-if="board.attcYn == 'Y' && item.attcCnt > 0" :attcNo="item.attcNo" :isHeader="false" :tabindex="tabindex+2" style="margin: 0;padding-left: 3px;padding-top: 10px;"/>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td class="center" colspan="1">{{$t('noContent')}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!-- 페이징 -->
      <pagination :pagingInfo="pagingInfo" :currentPage="searchParam.currentPage" v-model="currentPageIdx" :tabindex="tabindex+3"/>

      <!-- 게시물 검색 -->
      <div style="margin:0 auto;text-align:center;padding-bottom: 15px;" v-if="isSearchDiv()">
        <div class="board_search">
          <fieldset>
            <legend>{{$t('postSearch')}}</legend>
            <ul>
              <li v-if="isTabList" class="pr3">
                <label for="selectGrpCd"></label>
                <select v-model="searchParam.grpCd" id="selectGrpCd" :title="$t('slctSrch')" class="nor" :tabindex="tabindex+4">
                  <template v-for="(item, index) in grpList">
                    <option v-if="doSelect(item, index, 'grpCd')" selected :value="item.grpCd" :key="`sel_${index}`">{{item.grpNm}}</option>
                    <option v-else :value="item.grpCd" :key="`nosel_${index}`">{{item.grpNm}}</option>
                  </template>
                </select>
              </li>

              <template>
                <li v-if="isSearchList">
                  <label for="selectFieldCd"></label>
                  <select v-model="searchParam.fieldCdNm" id="selectFieldCd" style="width: 120px;height: 40px;" :tabindex="tabindex+5">
                    <template v-for="(item, index) in searchList">
                      <option v-if="doSelect(item,index, 'fieldCd')" :selected="true" :value="item.fieldCdNm" :key="`sel_${index}`">{{item.fieldNm}}</option>
                      <option v-else :value="item.fieldCdNm" :key="`nosel_${index}`">{{item.fieldNm}}</option>
                    </template>
                  </select>
                </li>
                <li v-if="isSearchList" class="pl5" style="height: 40px;">
                  <label for="searchKeyword"></label>
                  <input v-model="searchParam.keyword" id="searchKeyword" type="text" size="25" :placeholder="$t('srchInput')" class="search_input" @keydown="doSearchKeywordEnter" :tabindex="tabindex+6">
                </li>
                <li class="bt">
                  <img src="@/assets/img/board/nninc_simple/img/search_bt.gif" class="search_bt" :alt="$t('slctSrch')" @click="doSearch()" @keydown="doSearchKeywordEnter" style="vertical-align: middle;" :tabindex="tabindex+7">
                </li>
              </template>
            </ul>
          </fieldset>
        </div>
      </div>
    </div>
    <div v-else>
      <overlay v-if="!isContent"></overlay>
    </div>
  </div>
</template>

<script>
import childTab from '@/views/component/ChildTab'
import pagination from '@/views/component/Pagination'
import fileDownload from '@/views/component/fileDownload'
import overlay from "@/views/component/Overlay";

import { boardDefaults } from '@/libs/sanitize'
import {decodeHTMLEntities} from '@/libs/comm/util'

export default {
  components: {
    childTab,
    pagination,
    fileDownload,
    overlay,
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    grpList: {
      type: Array,
      default: () => []
    },
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed:{
    grpSize() {
      return this.grpList.reduce((e,cur) => {
                  return cur.hiddenYn!="Y"?e+1:0
              },0)
    }
  },
  watch: {
    currentPageIdx: {
      handler(newVal, oldVal) {   
        const firData=(newVal??1) == 0?1:newVal??1;
        const seconData=(oldVal??1) == 0?1:oldVal??1;
      
        if(firData != seconData) {
          this.doPage(firData)
        }
      }
    },
    'board.boardNo'(newVal){
      if(this.searchParam.contentNo != newVal) {
        this.setSearchParam();
        this.subContentNo = null;

        if(this.board.grpDispCd == 'CMS009.select' && this.grpList != null && this.grpSize > 0) {
          this.isTabList = true;
        }

        if(this.grpList != null && this.grpSize > 0){
          if(this.isTabList || this.board.grpYn != 'Y' || this.board.ndisYn == 'Y' ) {
            const query = {...this.$route.query};

            this.searchParam.grpCd = query[`s${this.depth}`];
            this.doList();
          }else{
            this.setCurrentTab();
          }
        } else {
          this.doList();
        }
      }
    },
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.setSearchParam();
          this.currentPageIdx = newVal.page;
          
          if(this.grpList != null && this.grpSize > 0) {
            this.setCurrentTab();
          }

          // 컨텐츠유형 : 게시판 
          if(this.board.grpYn == 'N' && this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
            if(newVal[`s${this.depth-1}`] == oldVal[`s${this.depth-1}`]) {
              // 탭 변경 이외 조회가능하게 
              this.doList()
            } 
          }else{
            // 컨텐츠유형 : 분류
            this.doList();
          }
        }
      }
    }
  }, 
  //뒤로 가기나 메뉴 이동을 통한 조회를 하기 위해 존재함
  created() {
    const grpYn = this.board?.grpYn;
    const grpDispCd = this.board?.grpDispCd;
    const ndisYn = this.board.ndisYn;

    const initPage = this.$route?.query?.page;

    if(initPage != null && initPage != ''){
      this.currentPageIdx = initPage
    }

    this.setSearchParam();

    if(this.$route.name.indexOf('direct') == 0) {
      this.routeDiv = 'direct'
    }

    //분류가 존재하는경우 분류를 통한 조회
    if(this.grpList != null && this.grpSize > 0 && grpDispCd != null && grpDispCd != '' && grpYn == 'Y') {
      if(ndisYn == 'Y' || grpDispCd == 'CMS009.select'){
        // 게시물 상세에서 뒤로가기 진행시 게시판 설정이 비공개이거나 선택상자 사용시 탭 세팅 안하고 바로 조회
        this.doList();
      }else{
        this.setCurrentTab()
      }
    }else{
      const boardNo = this.board?.boardNo;

      if(boardNo != null && boardNo != '') {
        // 글쓰기 및  상세 & 뒤로가기 할 때 조회 되게 
        this.doList();
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo: '',
        currentPage: 1,
        pagePerNo: null,
        fieldCdNm: null,
        grpCd: null, 
        keyword: '', 
      },
      searchList: [],
      pagingInfo: {
        totalCnt: 0,
        firstPage: 0,
        lastPage: 0,
        listItemNo: 0,
        nextPage: 0,
        prevPage: 0,
        totalPage: 0,
      },
      currentPageIdx: 1,
      dataItemList: [],
      currentTab: 'none',
      isTabList: false,
      isSearchList: false,
      isContent: false,
      subContentNo: '',
      subContentType: 'A',
      routeDiv: 'dynamic',
      isLoading: false,
      boardDefaults
    }
  },
  methods: {
    //기본파라미터 세팅
    setSearchParam() {
      const query = this.$route.query;
      const searchCD = query?.searchCD;
      const grpYn = this.board?.grpYn;
      const grpCd = query[`s${this.depth}`];
      const page = parseInt(query?.page);
      const pagePerNo = query?.pagePerNo;

      if(grpYn == 'Y' && this.grpSize > 0) {
        this.searchParam.grpCd = grpCd;
      }

      if(searchCD != null && searchCD != '') {
        this.searchParam.fieldCdNm = searchCD;
      }

      if(pagePerNo != null && pagePerNo != '') {
        this.searchParam.pagePerNo = pagePerNo;
      }else{
        this.searchParam.pagePerNo = this.board.pagePerNo;
      }

      this.searchParam.currentPage = page > 0 ? page:1;
      this.searchParam.keyword = query?.keyword;
      this.searchParam.contentNo = this.board.boardNo;
    },
    setMetaInfo() {
      const query = this.$route.query;
      // 현재 뎁스의 그룹코드
      const grpCd = query[`s${this.depth}`];
      // 현재 URL
      const url = document.location.href;
      // 게시판 이름
      const boardNm = this.board.boardNm;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 그룹리스트
      const grpList = this.grpList;

      let metaInfoData = {
        title: boardNm,
        ogTitle: boardNm,
        ogUrl: url,
        ogDescription: boardNm,
        ogImage: '/logo.png',
      };
      
      if(grpList != null && this.grpSize > 0 && grpYn == 'Y') {
        const curTabData = grpList.find(e => e.grpCd == grpCd);

        if(curTabData != null) {
          const grpNm = curTabData.grpNm;
          if(grpNm != null && grpNm != '') {
            metaInfoData.title = `${this.$t('nationalBdna')}: ${boardNm} > ${grpNm}`;
          }
        }
      }else{
        metaInfoData.title = `${this.$t('nationalBdna')}: ${boardNm}`;
      }

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    isTab(){
      // 그룹 탭 리스트 
      const grpList = this.grpList;
      // 그룹 사용 컴포넌트 코드(탭 / 선택상자)
      const grpDispCd = this.board.grpDispCd;
      // 그룹사용여부
      const grpYn = this.board.grpYn;
      // 비공개 여부
      const ndisYn = this.board.ndisYn;
      // 비공개 필수 여부
      const ndisRequYn = this.board.ndisRequYn;

      if(grpList == null || this.grpSize < 1){
        return false;
      }

      if(grpYn != 'Y' || grpDispCd != 'CMS009.tab'){
        return false;
      }

      if(ndisYn == 'Y' && ndisRequYn == 'Y'){
        return false;
      }

      return true;
    },
    isSearchDiv() {
      const grpDispCd = this.board.grpDispCd;
      // 비공개 필수 여부
      const ndisRequYn = this.board.ndisRequYn;
      // 검색조건 선택 리스트
      const searchList = this.searchList;
      // 해당 게시판 그룹리스트(선택상자인 경우)
      const grpList = this.grpList;


      if(searchList == null || searchList.length < 1){
        this.isSearchList = false;
      }else{
        this.isSearchList = true;
      }

      if(grpDispCd == 'CMS009.tab' || grpList == null || this.grpSize < 1) {
        this.isTabList = false;
      }else{
        this.isTabList = true;
      }

      //검색조건이 없는경우 제외 하지만 그룹코드가 존재하는 경우 검색조건 활성화
      if(!this.isTabList && !this.isSearchList){
        return false;
      }
      
      return true;
    },
    changeTab(data, contentType, key, isSameAllow=false) {
      const query = {...this.$route.query};
      query[`s${this.depth}`] = key;
      let isSearch = true;
      //주소가 같이 않으면주소 변경
      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
        
        if(contentType != 'A') {
          this.searchParam.grpCd = data
        }else{
          this.searchParam.contentNo = data
        }

        delete param.query.page;
        this.searchParam.currentPage = 1;

        isSearch = false
        this.$router.push(param);
      }

      //컨텐츠가 분류가아니면 하위 컴포넌트 조회
      if(contentType != 'A'){
        //하위컨텐츠의 타입 전달
        this.subContentType = contentType
        this.isContent = true;
        this.subContentNo = data
      }else{
        this.isContent = false;
        this.subContentNo = '';
        //그룹코드를 통한 데이터 조회를 위한 설정
        if(this.searchParam.grpCd == null || this.searchParam.grpCd == '') {
          this.searchParam.grpCd = data
        }

        if(isSearch){
          this.doList()
        }
      }
    },
    setCurrentTab() {
      const isFirst = this.$route.params.isFirst
      
      if(isFirst != null&& isFirst != '') {
        const grpList = this.grpList
        if(grpList != null && this.grpSize > 0) {
          const firstGrpList = grpList[0];
          const grpCd = firstGrpList.grpCd;
          const contentType = firstGrpList.contentType;
          const linkUrl = firstGrpList.linkUrl;

          if(this.currentTab == null){
            this.changeTab(contentType!='A'?linkUrl:grpCd,contentType,grpCd)
          }else{
            this.currentTab = grpCd
          }
        }
      }else{
        this.currentTab = this.$route.query[`s${this.depth}`]
      }
    },
    doSearchKeywordEnter(event) {
      if(event.keyCode == 13) {
        this.doSearch();
      }
    },
    doList() {
      this.isLoading = true;
      this.$tran('/site/external/contents/selectPostFaqList', this.searchParam).then(response => {
        this.searchList = response.data.searchList;
        this.dataItemList = response.data.postList;
        this.pagingInfo = response.data.pagingInfo;
        this.rows = response.data.rows;
        
        const postNo = this.$route.query?.postNo;

        if(postNo != null && postNo != '') {
          const item = this.dataItemList.find(e => e.postNo == postNo);
          
          if(item != null) {
            this.doDetail(item);
          }
        }
        this.setMetaInfo();
      }).finally(() => {
        this.isLoading=false
      })
    },
    doDetail(item) {
      if(item != null && item.active){
        item.active = false
      }else{
        for(const idx in this.dataItemList){
          this.$set(this.dataItemList[idx], 'active', false)
        }

        this.$set(item, 'active', true)
      }
    },
    doSelect(item, index, type) {
      if((this.searchParam.fieldCdNm == null || this.searchParam.fieldCdNm == '') && type == 'fieldCd') {
          if(index == 0){
            this.searchParam.fieldCdNm=item.fieldCdNm
          return true
        }
      }else if((this.searchParam.grpCd == null || this.searchParam.grpCd == '') && type == 'grpCd') {
        if(index == 0){
          this.searchParam.grpCd=item.grpCd
          return true
        }
      }else{
        return false
      }
    },
    doPage(index) {
      const query = {...this.$route.query};
      const currentPage = index!=null?index:1; 

      this.searchParam.currentPage=currentPage;
      this.currentPageIdx=currentPage;
      query.page = currentPage;

      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
    
        this.$router.push(param);
      }
    },
    doSearch() {
      const query = {...this.$route.query};
      // 검색 코드
      const searchCD = this.searchParam.fieldCdNm;
      // 검색어
      const keyword =  this.searchParam.keyword;
      // 그룹 
      const grpCd = this.searchParam.grpCd;
      // 페이징 수
      const pagePerNo = this.searchParam.pagePerNo;
      // 그룹표시 코드
      const grpDispCd =  this.board.grpDispCd;

      if(grpDispCd == 'CMS009.select') {
        query[`s${this.depth}`] = grpCd;
      }

      if(pagePerNo != null && pagePerNo != '') {
        query.pagePerNo = pagePerNo;
      }
      
      if(keyword != null && keyword != '') {
        query.searchCD = searchCD;
        query.keyword = keyword;
      }else{
        delete query.searchCD;
        delete query.keyword;
      }

      delete query.page;
      this.searchParam.currentPage=1;

      if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
        const param = {query};
        this.$router.push(param);

        this.doList()
      }
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doKeydownDetail(event, item) {
      if(event.keyCode == 13) {
        this.doDetail(item);
      }
    }
  }
}
</script>