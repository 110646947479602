<template>
  <div id="sidebar">
    <div id="sidemenu">
      <ul class="sub_menuw">
        <li>
          <a href="/" :tabindex="tabindex">
            <img src="@/assets/img/sample01/img/common/home.png" alt="home">
          </a>
        </li>
        <li v-if="depth1MenuList != null && depth1MenuList.length > 0" class="hasDepth one" :class="depth1Class">
          <a @click="doDepth1NmClick()" @keydown="doKeydownDepth1Nm($event)" :tabindex="tabindex+1">
            <span>{{$t(curDepth1.mulLangCd)}}</span>
          </a>
          <transition name="slide">
            <ul class="depth2" :style="depth1Style" v-show="isDepth1Show">
              <template v-for="(item, index) in depth1MenuList">
                <li v-if="item.hiddenYn != 'Y'" :key="index" >
                  <a v-html="$sanitize($t(item.mulLangCd))" @click="doDepth1Link(item)"  @keydown="doKeydownDepth1Link($event, item)" :tabindex="tabindex+1"></a>
                </li>
              </template>
            </ul>
          </transition>
        </li>
        <li v-if="depth2MenuList!= null && depth2MenuList.length > 0" class="hasDepth two" :class="depth2Class">
          <a @click="doDepth2NmClick()" @keydown="doKeydownDepth2Nm($event)" :tabindex="tabindex+2">
            <span>{{$t(curDepth2.mulLangCd)}}</span>
          </a>
          <transition name="slide2">
            <ul class="depth2" :style="depth2Style" v-show="isDepth2Show"> 
              <template v-for="(item, index) in depth2MenuList">
                <li v-if="item.hiddenYn != 'Y'" :key="index">
                  <a @click="doDepth2Link(item)" @keydown="doKeydownDepth2Link($event, item)" :tabindex="tabindex+2">
                    <span v-html="$sanitize($t(item.mulLangCd))" class="pl0" style="display: inline-block; padding: 0px 6px;"></span>
                    <img v-if="item.menuTypeCd=='CMS007.link' && item.link.charAt(0) != '/'" src="@/assets/img/skin01/img/common/new_window_icon.gif" :alt="$t('moveNewWin')">
                  </a>
                </li>
              </template>
            </ul>
          </transition>
        </li>
        <li v-if="depth3MenuList!= null && depth3MenuList.length > 0" class="hasDepth three" :class="depth3Class">
          <a @click="doDepth3NmClick()" @keydown="doKeydownDepth3Nm($event)" :tabindex="tabindex+3">
            <span>{{$t(curDepth3.mulLangCd)}}</span>
          </a>
          <transition name="slide3">
            <ul class="depth2" :style="depth3Style" v-show="isDepth3Show"> 
              <template v-for="(item, index) in depth3MenuList">
                <li v-if="item.hiddenYn != 'Y'" :key="index">
                  <a @click="doDepth3Link(item)" @keydown="doKeydownDepth3Link($event, item)" :tabindex="tabindex+3">
                    <span v-html="$sanitize($t(item.mulLangCd))" class="pl0" style="display: inline-block; padding: 0px 6px;"></span>
                    <img v-if="item.menuTypeCd=='CMS007.link' && item.link.charAt(0) != '/'" src="@/assets/img/skin01/img/common/new_window_icon.gif" :alt="$t('moveNewWin')">
                  </a>
                </li>
              </template>
            </ul>
          </transition>
        </li>
        <li v-if="depth4MenuList!= null && depth4MenuList.length > 0" class="hasDepth four" :class="depth4Class">
          <a @click="doDepth4NmClick()" @keydown="doKeydownDepth4Nm($event)" :tabindex="tabindex+4">
            <span>{{$t(curDepth4.mulLangCd)}}</span>
          </a>
          <transition name="slide4">
            <ul class="depth2" :style="depth4Style" v-show="isDepth4Show"> 
              <template v-for="(item, index) in depth4MenuList">
                <li v-if="item.hiddenYn != 'Y'" :key="index">
                  <a @click="doDepth4Link(item)" @keydown="doKeydownDepth4Link($event, item)" :tabindex="tabindex+4">
                    <span v-html="$sanitize($t(item.mulLangCd))"></span>
                    <img v-if="item.menuTypeCd=='CMS007.link' && item.link.charAt(0) != '/'" src="@/assets/img/skin01/img/common/new_window_icon.gif" :alt="$t('moveNewWin')">
                  </a>
                </li>
              </template>
            </ul>
          </transition>
        </li>
      </ul>
      <div class="share">
        <a @click="doShareCont()" class="btn_share" @keydown="doKeydownShareCont($event)" :tabindex="tabindex+5">공유하기</a>
        <div id="share_cont" v-show="isSharContShow">
          <ul>
            <li class="facebook">
              <a @click="doShareFacebook()" @keydown="doKeydownShareFacebook($event)" :tabindex="tabindex+5">
                <span>페이스북</span>
              </a>
            </li>
            <li class="twitter">
              <a @click="doShareTwitter()"  @keydown="doKeydownShareTwitter($event)" :tabindex="tabindex+5">
                <span>트위터</span>
              </a>
            </li>
            <!-- <li class="kakao">
              <a @click="doShareKakao()" @keydown="doKeydownShareKakao($event)" :tabindex="tabindex+5">
                <span>카카오톡</span>
              </a>
            </li> -->
            <li class="nblog">
              <a @click="doShareBlog()" @keydown="doKeydownShareBlog($event)" :tabindex="tabindex+5">
                <span>네이버블로그</span>
              </a>
            </li>
            <li class="copyurl">
              <a @click="doCopyLink()" id="copy-link" @keydown="doKeydownCopyLink($event)" :tabindex="tabindex+5">
                <span>url복사</span>
              </a>
            </li>
            <li class="close">
              <a @click="doShareCont()" @keydown="doKeydownShareCont($event)" :tabindex="tabindex+5">
                <span>닫기</span>
              </a>
            </li>
          </ul>
        </div>
        <a @click="doPrint()" class="btn_print" @keydown="doKeydownPrint($event)" :tabindex="tabindex+6">
          인쇄
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import  printJS  from 'print-js'

export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  computed: {
    upMenuId(){
      return this.$route.query?.upMenuId;
    },
    menuId(){
      return this.$route.query?.menuId;
    },
    ...mapGetters({
      depth1All: 'depth1All',
      depth2All: 'depth2All',
      depth3All: 'depth3All',
      depth4All: 'depth4All',
    }),
    // 라우터에 있는 upMenuId 가지고  3뎁스 메뉴 없을 경우 2뎁스 있는지 보는 용도
    depth2Data() {
      if(this.depth2All != null) {
        return this.depth2All.filter((e) => {
          return e.seqChar.indexOf(this.menuId) > -1 
        })
      }else {
        return []
      }
    },
    // 라우터에 있는 upMenuId 가지고  3뎁스 메뉴까지 있는지 보는 용도
    depth3Data() {
      if(this.depth3All != null) {
        return this.depth3All.filter((e) => {
          return e.seqChar.indexOf(this.menuId) > -1 
        })
      }else{
        return []
      }
    },
    // 라우터에 있는 upMenuId 가지고  4뎁스 메뉴까지 있는지 보는 용도
    depth4Data() {
      if(this.depth4All != null) {
        return this.depth4All.filter((e) => {
          return e.seqChar.indexOf(this.menuId) > -1 
        })
      }else{
        return []
      }
    }
  },
  watch: {
    // 뎁스 변경시 필요
    '$route.query': {
      handler(newVal, oldVal) {
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.setSideMenu();
          
          if(this.isDepth1Show) {
            this.doDepth1NmClick();
          }

          if(this.isDepth2Show) {
            this.doDepth2NmClick();
          }

          if(this.isDepth3Show) {
            this.doDepth3NmClick();
          }

          if(this.isDepth4Show) {
            this.doDepth4NmClick();
          }
        }
      }
    },
    // 새로고침을 위해 필요
    depth1All(newVal) {
      if(newVal != null && newVal != '') {
        this.setSideMenu();
      }
    },
  },
  created() {
    this.setSideMenu();
  },
  data() {
    return {
      isDepth1Show: false,
      isDepth2Show: false,
      isDepth3Show: false,
      isDepth4Show: false,

      depth1MenuList: [],
      curDepth1: {},
      depth1Style: {},
      depth1Class: '',

      depth2MenuList: [],
      curDepth2: {},
      depth2Style: {},
      depth2Class: '',

      depth3MenuList: [],
      curDepth3: {},
      depth3Style: {},
      depth3Class: '',

      depth4MenuList: [],
      curDepth4: {},
      depth4Style: {},
      depth4Class: '',

      isSharContShow: false,
    }
  },
  methods: {
    setSideMenu() {
      this.depth4MenuList = [];
      this.depth3MenuList = [];
      this.depth2MenuList = [];
      this.depth1MenuList = [];

      let contentLink = false;
      let item = {};
      
      // 4뎁스 부터 찾기
      if(this.depth4Data != null && this.depth4Data.length > 0) {
        this.curDepth4 = this.depth4Data[0]; // 현제 4뎁스 정보
        item = this.depth4Data[0];
        this.depth4MenuList = this.depth4All.filter((e) => {return e.seqChar.indexOf(this.curDepth4.upMenuId) > -1 }) // 3뎁스 전체 리스트
        
        const depthData = this.curDepth4.seqChar.split('_');
        const curDepth1MenuId = depthData[0];  // 1뎁스
        const curDepth2MenuId = depthData[1];  // 2뎁스
        const curDepth3MenuId = depthData[2];  // 3뎁스
        const curDepth4MenuId = depthData[3];  // 4뎁스
        
        // 3뎁스 세팅
        this.depth3MenuList = this.depth3All.filter((e) => { return e.seqChar.indexOf(curDepth2MenuId) > -1 })  // 3뎁스 전체 리스트
        this.curDepth3 = this.depth3MenuList.find(e=> e.menuId == curDepth3MenuId); // 현제 3뎁스 정보

        // 2뎁스 세팅
        this.depth2MenuList = this.depth2All.filter((e) => { return e.seqChar.indexOf(curDepth1MenuId) > -1 })  // 2뎁스 전체 리스트
        this.curDepth2 = this.depth2MenuList.find(e=> e.menuId == curDepth2MenuId); // 현제 2뎁스 정보
        
        // 1뎁스 세팅
        this.depth1MenuList = this.depth1All; // // 1뎁스 전체 리스트
        this.curDepth1 = this.depth1MenuList.find(e=> e.menuId == curDepth1MenuId); // 현제 1뎁스 정보

        if(this.curDepth3.menuTypeCd == 'CMS007.link') {
          contentLink = this.curDepth3.link.charAt(0) == '/';

          if(contentLink) {
            // 내부링크일 경우 현재 라우터 주소랑 3뎁스중 같은 링크 가지고 있는거 
            this.curDepth4 = this.depth4MenuList.find(e=> this.urlCompare(e.link, this.$route.fullPath) && e.menuTypeCd =='CMS007.link');
            item = this.curDepth4;
          }
        }
      }else if(this.depth3Data != null && this.depth3Data.length > 0) { // 3뎁스
        this.curDepth3 = this.depth3Data[0]; // 현제 3뎁스 정보
        item = this.depth3Data[0];
        this.depth3MenuList = this.depth3All.filter((e) => {return e.seqChar.indexOf(this.curDepth3.upMenuId) > -1 }) // 3뎁스 전체 리스트
        
        const depthData = this.curDepth3.seqChar.split('_');
        const curDepth1MenuId = depthData[0];  // 1뎁스
        const curDepth2MenuId = depthData[1];  // 2뎁스
        const curDepth3MenuId = depthData[2];  // 3뎁스

        // 2뎁스 세팅
        this.depth2MenuList = this.depth2All.filter((e) => { return e.seqChar.indexOf(curDepth1MenuId) > -1 })  // 2뎁스 전체 리스트
        this.curDepth2 = this.depth2MenuList.find(e=> e.menuId == curDepth2MenuId); // 현제 2뎁스 정보
        
        // 1뎁스 세팅
        this.depth1MenuList = this.depth1All; // // 1뎁스 전체 리스트
        this.curDepth1 = this.depth1MenuList.find(e=> e.menuId == curDepth1MenuId); // 현제 1뎁스 정보

        if(this.curDepth3.menuTypeCd == 'CMS007.link') {
          contentLink = this.curDepth3.link.charAt(0) == '/';

          if(contentLink) {
            // 내부링크일 경우 현재 라우터 주소랑 3뎁스중 같은 링크 가지고 있는거 
            this.curDepth3 = this.depth3MenuList.find(e=> this.urlCompare(e.link, this.$route.fullPath) && e.menuTypeCd =='CMS007.link');
            item = this.curDepth3;
          }
        }
      }else{
        if(this.depth2Data != null && this.depth2Data.length > 0) {
          // 2뎁스 세팅
          this.depth3MenuList = [];
          this.curDepth2 = this.depth2Data[0]; // 현제 2뎁스 정보
          item = this.depth2Data[0];
          this.depth2MenuList =  this.depth2All.filter((e) => {return e.seqChar.indexOf(this.curDepth2.upMenuId) > -1 })// 2뎁스 전체 리스트
  
          const depthData = this.curDepth2.seqChar.split('_');
          const curDepth1MenuId = depthData[0];  // 1뎁스
          const curDepth2MenuId = depthData[1];  // 2뎁스

          // 1뎁스
          this.depth1MenuList = this.depth1All; // // 1뎁스 전체 리스트
          this.curDepth1 = this.depth1MenuList.find(e=> e.menuId == curDepth1MenuId); // 현제 1뎁스 정보
        }else{
          // 1뎁스
          this.depth1MenuList = this.depth1All; // // 1뎁스 전체 리스트
          this.curDepth1 = this.depth1MenuList.find(e=> e.menuId == this.menuId); // 현제 1뎁스 정보
          item = this.curDepth1;
        }
      }

      this.$store.dispatch('setCurMenu', item);
    },
    doDepth1NmClick() {
      if(!this.isDepth1Show) {
        // 1뎁스 활성화
        this.isDepth1Show = true;
        this.depth1Class = 'on';

        // 2뎁스 비활성화
        this.isDepth2Show = false;
        this.depth2Class = '';

        // 3뎁스 비활성화
        this.isDepth3Show = false;
        this.depth3Class = '';

        // 4뎁스 비활성화
        this.isDepth4Show = false;
        this.depth4Class = '';
      }else{
        this.depth1Class = '';
        this.isDepth1Show = false;
      }
    },
    doDepth2NmClick() {
      if(!this.isDepth2Show) {
        // 1뎁스 비활성화
        this.isDepth1Show = false;
        this.depth1Class = '';

        // 2뎁스 활성화
        this.isDepth2Show = true;
        this.depth2Class = 'on';

        // 3뎁스 비활성화
        this.isDepth3Show = false;
        this.depth3Class = '';

        // 4뎁스 비활성화
        this.isDepth4Show = false;
        this.depth4Class = '';
      }else{
        this.isDepth2Show = false;
        this.depth2Class = '';
      }
    },
    doDepth3NmClick() {
      if(!this.isDepth3Show) {
        // 1뎁스 비활성화
        this.isDepth1Show = false;
        this.depth1Class = '';

        // 2뎁스 비활성화
        this.isDepth2Show = false;
        this.depth2Class = '';

        // 3뎁스 활성화
        this.isDepth3Show = true;
        this.depth3Class = 'on';

        // 4뎁스 비활성화
        this.isDepth4Show = false;
        this.depth4Class = '';
      }else{
        this.isDepth3Show = false;
        this.depth3Class = '';
      }
    },
    doDepth4NmClick() {
      if(!this.isDepth4Show) {
        // 1뎁스 비활성화
        this.isDepth1Show = false;
        this.depth1Class = '';

        // 2뎁스 비활성화
        this.isDepth2Show = false;
        this.depth2Class = '';

        // 3뎁스 비활성화
        this.isDepth3Show = false;
        this.depth3Class = '';

        // 4뎁스 활성화
        this.isDepth4Show = true;
        this.depth4Class = 'on';
      }else{
        this.isDepth4Show = false;
        this.depth4Class = '';
      }
    },
    doDepth1Link(depth1) {
      const param = {};
      let menuTypeCd = null;
      let selectedDepth2 = {};
      let selectedDepth3 = {};
      let selectedDepth4 = {};
      let item = {};

      if(this.curDepth1.menuId == depth1.menuId) {
        // 현재 2뎁스 메뉴ID랑 클릭한 1뎁스 메뉴ID랑 같은 경우 현재 있는 2뎁스 첫번째꺼 세팅
        selectedDepth2 = this.depth2MenuList[0];
        item = this.depth2MenuList[0];
      }else{
        const depth2List = this.depth2All.filter((e) => {return e.seqChar.indexOf(depth1.menuId) > -1 }); // 선택한 1뎁스의 2뎁스 전체 리스트

        if(depth2List != null && depth2List.length > 0) {
          // 2뎁스 메뉴 리스트 있을 경우 첫번째꺼 자동 세팅
          selectedDepth2 = depth2List[0];
          item = depth2List[0];
        }
      }

      const depth3List = this.depth3All.filter((e) => {return e.seqChar.indexOf(selectedDepth2.menuId) > -1 });

      if(depth3List != null && depth3List.length > 0) {
        const depth4List = this.depth4All.filter((e) => {return e.seqChar.indexOf(selectedDepth3.menuId) > -1 });

        if(depth4List != null && depth4List.length > 0) {
          // 4뎁스까지 있을 경우
          selectedDepth4 = depth4List[0];
          item = selectedDepth4;
          menuTypeCd = selectedDepth4.menuTypeCd;

          // 4뎁스(menuId: 4뎁스menuId/upmenuId: 3뎁스menuId)
          param.query = {menuId: selectedDepth4.menuId, upMenuId: selectedDepth4.upMenuId, s0: selectedDepth4.firstGrpCd};
        }else{
          // 3뎁스까지 있을 경우
          selectedDepth3 = depth3List[0];
          item = depth3List[0];
          menuTypeCd = selectedDepth3.menuTypeCd;

          // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
          param.query = {menuId: selectedDepth3.menuId, upMenuId: selectedDepth3.upMenuId, s0: selectedDepth3.firstGrpCd};
        }
      }else{
        if(selectedDepth2 != null) {
          // 2뎁스까지만 있을 경우
          menuTypeCd = selectedDepth2.menuTypeCd;
          item = selectedDepth2;
          // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
          param.query = {menuId: selectedDepth2.menuId, upMenuId: selectedDepth2.upMenuId, s0: selectedDepth2.firstGrpCd};
        }else{
          menuTypeCd = depth1.menuTypeCd;
          item = depth1;
          // 1뎁스(menuId: 1뎁스menuId/upmenuId: 1뎁스menuId)
          param.query = {menuId: depth1.menuId, upMenuId: depth1.menuId, s0:null}; 
        }
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'contentEng';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = item.link.charAt(0) == '/';
        
          if(contentLink) { // 내부 컨텐츠 링크
            param.path=item.link??'/'
            param.query = {};
          }else{  // 외부 링크
            window.open(item.link, "_blank");
            return
          }   
        }else{
          link = '/';
        } 
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);

      if(routeQuery != nextQuery) {
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param)
      }

      this.doDepth1NmClick();
      this.setSideMenu();
    },
    doDepth2Link(depth2) {
      const query = this.$route.query;
      const param = {};
      let menuTypeCd = null;
      let selectedDepth3 = {};
      let selectedDepth4 = {};
      let item = {};
      
      if(query.upMenuId == depth2.menuId) {
        // 현재 2뎁스(라우터upMenuId)랑 클릭한 2뎁스 메뉴ID랑 같은 경우 3뎁스 첫번째꺼 세팅
        selectedDepth3 = this.depth3MenuList[0]; 
        item = this.depth3MenuList[0];
        menuTypeCd = selectedDepth3.menuTypeCd;
        
        // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
        param.query = {menuId: selectedDepth3.menuId, upMenuId: selectedDepth3.upMenuId, s0: selectedDepth3.firstGrpCd};
      }else{
        const depth3List = this.depth3All.filter((e) => {return e.seqChar.indexOf(depth2.menuId) > -1 });  // 선택된 2뎁스 menuId의 3뎁스 전체 리스트
        
        if(depth3List != null && depth3List.length > 0) {
          const depth4List = this.depth4All.filter((e) => {return e.seqChar.indexOf(selectedDepth3.menuId) > -1 });

          if(depth4List != null && depth4List.length > 0) {
            // 4뎁스까지 있을 경우
            selectedDepth4 = depth4List[0];
            item = selectedDepth4;
            menuTypeCd = selectedDepth4.menuTypeCd;

            // 4뎁스(menuId: 4뎁스menuId/upmenuId: 3뎁스menuId)
            param.query = {menuId: selectedDepth4.menuId, upMenuId: selectedDepth4.upMenuId, s0: selectedDepth4.firstGrpCd};
          }else{
            // 3뎁스까지 있을 경우(첫번째꺼 자동 세팅)
            selectedDepth3 = depth3List[0];
            item = depth3List[0];
            menuTypeCd = selectedDepth3.menuTypeCd;
            
            // 3뎁스(menuId: 3뎁스menuId/upmenuId: 2뎁스menuId)
            param.query = {menuId: selectedDepth3.menuId, upMenuId: selectedDepth3.upMenuId, s0: selectedDepth3.firstGrpCd};
          }
        }else{
          // 2뎁스까지만 있을 경우
          menuTypeCd = depth2.menuTypeCd;
          item = depth2;
          // 2뎁스(menuId: 2뎁스menuId/upmenuId: 1뎁스menuId)
          param.query = {menuId: depth2.menuId, upMenuId: depth2.upMenuId, s0: depth2.firstGrpCd};
        }
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'contentEng';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = item.link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path=item.link??'/'
            param.query = {};
          }else{  // 외부 링크
            window.open(item.link, "_blank");
            return
          }   
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);

      if(routeQuery != nextQuery){
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param)
      }

      this.doDepth2NmClick();
      this.setSideMenu();
    },
    doDepth3Link(depth3) {
      let selectedDepth4 = {};
      const param = {};
      let item = {};
      let menuTypeCd = null;

      const depth4List = this.depth4All.filter((e) => {return e.seqChar.indexOf(depth3.menuId) > -1 });

      if(depth4List != null && depth4List.length > 0) {
        // 4뎁스까지 있을 경우
        selectedDepth4 = depth4List[0];
        item = selectedDepth4;
        menuTypeCd = selectedDepth4.menuTypeCd;

        // 4뎁스(menuId: 4뎁스menuId/upmenuId: 3뎁스menuId)
        param.query = {menuId: selectedDepth4.menuId, upMenuId: selectedDepth4.upMenuId, s0: selectedDepth4.firstGrpCd};
      }else{
        item = depth3;
        menuTypeCd = depth3.menuTypeCd;
        // menuId: 3뎁스menuId/upmenuId: 2뎁스menuId
        param.query = {menuId: depth3.menuId, upMenuId: depth3.upMenuId, s0: depth3.firstGrpCd};
      }

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'contentEng';
      }else{
        let link = item?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = item.link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부 링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);
      param.params = {isFirst: item.menuId};
      
      if(routeQuery != nextQuery){
        this.$store.dispatch('setCurMenu', item);
        this.$router.push(param)
      }
      
      this.doDepth3NmClick();
      this.setSideMenu();
    },
    doDepth4Link(depth4) {
      const param = {};
      const menuId = depth4.menuId;
      const menuTypeCd = depth4.menuTypeCd;

      // menuId: 4뎁스menuId/upmenuId: 3뎁스menuId
      param.query = {menuId: menuId, upMenuId: depth4.upMenuId, s0: depth4.firstGrpCd};

      if(menuTypeCd == 'CMS007.content') {
        param.name = 'contentEng';
      }else{
        let link = depth4?.link.trimStart();

        // 링크내용이 있을경우
        if(link != null && link != '') {
          const contentLink = depth4.link.charAt(0) == '/';

          if(contentLink) { // 내부 컨텐츠 링크
            param.path = link;
          }else{  // 외부 링크
            window.open(link, "_blank");
            return
          }
        }else{
          link = '/';
        }
        param.path=link;
        param.query={};
      }

      const routeQuery = JSON.stringify(this.$route.query);
      const nextQuery = JSON.stringify(param.query);
      param.params = {isFirst: depth4.menuId};
      
      if(routeQuery != nextQuery){
        this.$store.dispatch('setCurMenu', depth4);
        this.$router.push(param)
      }
      
      this.doDepth4NmClick();
      this.setSideMenu();
    },
    doPrint() {
      printJS({
        printable: "swsize", //Id to print content 
        type: "html",
        css: ["/css/sub_layout_notmobile.css", "/css/base.css", "/css/template.css", "/css/head_layout.css", "/css/foot_layout.css", "/css/sub_layout.css", "/css/sub_design.css"],
        scanStyles: false,
        documentTitle: `${this.$t(this.curDepth1.mulLangCd)} > ${this.$t(this.curDepth2.mulLangCd)} > ${this.$t(this.curDepth3.mulLangCd)} | ${this.$t('nationalBdnaE')}`
      });
    },
    doCopyLink() {
      const url = window.document.location.href;
      const textarea = document.createElement("textarea");
      
      document.body.appendChild(textarea);
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");   
      document.body.removeChild(textarea);
      
      this.$swal.fire(this.$t('success'), 'URL이 복사 되었습니다. 원하시는 곳에 붙여넣기 해 주세요.', 'success');
    },
    doShareCont() {
      this.isSharContShow = !this.isSharContShow;
    },
    doShareFacebook() {
      const href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(document.location.href)}`;
      window.open(href, 'facebook', '');
    },
    doShareTwitter() {
      const href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(document.title)}&url=${encodeURIComponent(document.location.href)}`;
      window.open(href, 'X', '');
    },
    // doShareKakao() {
    //   Kakao.Story.share({
    //     url: document.location.href,
    //     text: document.title
    //   });
    // },
    doShareBlog() {
      const url = `http://blog.naver.com/openapi/share?url=${encodeURIComponent(document.location.href)}`;
      window.open(url, '네이버 블로그 공유하기', 'width=400,height=350');
    },
    urlCompare(url1, url2){
      const voUrl1 = this.urlSplit(url1)
      const voUrl2 = this.urlSplit(url2)
      if(voUrl1 != null && voUrl2 != null){
        const menuId1 = voUrl1.params.menuId
        const menuId2 = voUrl2.params.menuId
        const upMenuId1 = voUrl1.params.upMenuId
        const upMenuId2 = voUrl2.params.upMenuId
        
        const url1S0 = voUrl1.params?.s0;
        const url2S0 = voUrl2.params?.s0;

        if(menuId1 == menuId2 && upMenuId1 == upMenuId2){
          if(url1S0 != null && url1S0 != '' && url2S0 != null && url2S0 != '') {
            if(url1S0 == url2S0) {
              return true
            }
          }else{
            return true
          }
        }
      }
      return false
    },
    urlSplit(url){
      if(url == null){
        return null
      }
      const vaUrl = url.split('?');

      const voUrl = {};

      if(vaUrl != null && vaUrl.length > 0){
        voUrl.url = vaUrl[0];
        voUrl.params = {}

        if(vaUrl.length > 1){
          const vaParams = vaUrl[1].split('&');

          for(const idx in vaParams){
            const vaParam = vaParams[idx]
            
            const vaData = vaParam.split("=")

            if(vaData != null && vaData.length > 0){
              if(vaUrl.length > 1){ 
                voUrl.params[vaData[0]] = vaData[1]
              }else{
                voUrl.params[vaData[0]] = null
              }
            }
          }
        }
      }
      return voUrl;
    },
    doKeydownDepth1Nm(event) {
      if(event.keyCode == 13) {
        this.doDepth1NmClick();
      }
    },
    doKeydownDepth2Nm(event) {
      if(event.keyCode == 13) {
        this.doDepth2NmClick();
      }
    },
    doKeydownDepth3Nm(event) {
      if(event.keyCode == 13) {
        this.doDepth3NmClick();
      }
    },
    doKeydownDepth4Nm(event) {
      if(event.keyCode == 13) {
        this.doDepth4NmClick();
      }
    },
    doKeydownDepth1Link(event, item) {
      if(event.keyCode == 13) {
        this.doDepth1Link(item);
      }
    },
    doKeydownDepth2Link(event, item) {
      if(event.keyCode == 13) {
        this.doDepth2Link(item);
      }
    },
    doKeydownDepth3Link(event, item) {
      if(event.keyCode == 13) {
        this.doDepth3Link(item);
      }
    },
    doKeydownDepth4Link(event, item) {
      if(event.keyCode == 13) {
        this.doDepth4Link(item);
      }
    },
    doKeydownShareCont(event) {
      if(event.keyCode == 13) {
        this.doShareCont();
      }
    },
    doKeydownShareFacebook(event) {
      if(event.keyCode == 13) {
        this.doShareFacebook();
      }
    },
    doKeydownShareTwitter(event) {
      if(event.keyCode == 13) {
        this.doShareTwitter();
      }
    },
    // doKeydownShareKakao(event) {
    //   if(event.keyCode == 13) {
    //     this.doShareKakao();
    //   }
    // },
    doKeydownShareBlog(event) {
      if(event.keyCode == 13) {
        this.doShareBlog();
      }
    },
    doKeydownCopyLink(event) {
      if(event.keyCode == 13) {
        this.doCopyLink();
      }
    },
    doKeydownShareCont(event) {
      if(event.keyCode == 13) {
        this.doShareCont();
      }
    },
    doKeydownPrint(event) {
      if(event.keyCode == 13) {
        this.doPrint();
      }
    }
  },
}
</script>

<style scoped>
.slide-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-leave-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

.slide2-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide2-leave-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}
.slide2-enter-to, .slide2-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide2-enter, .slide2-leave-to {
   overflow: hidden;
   max-height: 0;
}

.slide3-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide3-leave-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}
.slide3-enter-to, .slide3-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide3-enter, .slide3-leave-to {
   overflow: hidden;
   max-height: 0;
}

.slide4-enter-active {
   -moz-transition-duration: 0.5s;
   -webkit-transition-duration: 0.5s;
   -o-transition-duration: 0.5s;
   transition-duration: 0.5s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}

.slide4-leave-active {
   -moz-transition-duration: 0.2s;
   -webkit-transition-duration: 0.2s;
   -o-transition-duration: 0.2s;
   transition-duration: 0.2s;
   -moz-transition-timing-function: linear;
   -webkit-transition-timing-function: linear;
   -o-transition-timing-function: linear;
   transition-timing-function: linear;
}
.slide4-enter-to, .slide4-leave {
   max-height: 100vh;
   overflow: hidden;
}

.slide4-enter, .slide4-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>