<template>
  <div id="board" style="width:100%;">
    <main-content v-if="isContent" :subYn="true" :contents="[{contentNo: subContentNo, contentType: subContentType}]" :grpList="grpList" :depth="depth+1" :tabindex="tabindex+100"/>
    <div v-if="!isContent && !isLoading">
    <div class="board_wrap"> 
      <div class="con_tab map tab_b">
        <ul>
          <li v-for="(item, index) in dataItemList" :key="index">
            <a @click="doDetail(item)" @keydown="doKeydownDetail($event, item)" :class="searchParam.postNo == item.postNo? 'on': ''" :tabindex="tabindex+1">{{ item.title }}</a>
          </li>
        </ul>
      </div>
      <div id="map_info">
        <div :class="['tinfo_txtw', `map_list${getGrpIndex()}`]">
          <template v-for="(item, index) in fieldList">
            <template v-if="item.fieldData != 'VR' && item.fieldData != 'FA'">
              <p v-if="item.fieldCdNm == 'field03'" :key="`title_${index}`" class="tit">
                {{ detailMain[item.fieldCdNm] }}
              </p>
              <p v-else-if="item.fieldCdNm == 'field08'" :key="`ctnt_${index}`" class="txt" v-html="$sanitize(decodeHTML(detailMain.field08), boardDefaults)"></p>
              <div v-else class="info_dew" :key="`info_${index}`">
                <div class="info_de">
                  <dl>
                    <dt>{{ item.fieldNm }}</dt>
                    <dd>{{ detailMain[item.fieldCdNm] }}</dd>
                  </dl>
                </div>
              </div>
            </template>
          </template>
          <div class="btn_wrap">
            <a @click="setTotalGuide()" @keydown="doKeydownTotalGuide($event)" title="BDNA Guide Map" class="on" :tabindex="tabindex+2">
              <span>BDNA Guide Map</span>
            </a>
            <a @click="doVr()" @keydown="doKeydownVr($event)" title="VR 보러가기" v-if="detailMain[vrCol] != null && detailMain[vrCol] != ''" class="vr_btn" :tabindex="tabindex+2">
              <span>VR 보러가기</span>
            </a>
            <a @click="doFa()" @keydown="doKeydownFa($event)" title="Convenient facilities" v-if="detailMain[faCol] != null && detailMain[faCol] != ''" class="fac_btn" style="width: 180px;" :tabindex="tabindex+2">
              <span>Convenient facilities</span>
            </a>
          </div>
          <div class="swiper-container">
            <swiper ref="swiper" class="swiper-wrapper" :options="swiperOption">
              <swiper-slide v-for="(item, index) in imageList" class="swiper-slide" :key="`bg0${index+1}`">
                <img :src="encodeURI(item?.imgUrl)" :alt="item.title" :tabindex="tabindex+index+3">
              </swiper-slide>
            </swiper>
            <!-- Add Pagination -->
            <div class="swiper-pagination" style="width: 100%;"></div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-else>
      <overlay></overlay>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

import { boardDefaults } from '@/libs/sanitize'
import {decodeHTMLEntities} from '@/libs/comm/util'

import overlay from "@/views_eng/component/Overlay";

export default {
  components: {
    Swiper,
    SwiperSlide,
      overlay,
  },
  computed: {
    swiper() {
      return this.$refs.swiper?.$swiper; 
    },
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal){
        if(newVal != null && oldVal != null && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          if(this.searchParam.postNo != newVal.postNo) {
            const data = this.dataItemList.find(e => e.postNo == newVal.postNo);

            if(data != null) {
              this.doDetail(data)
            }
          }
        }
      }
    }
  },
  props: {
    board: {
      type: Object,
      default: () => new Object()
    },
    depth: {
      type: Number
    },
    tabindex: {
      type: Number,
      default: 1
    },
  },
  //뒤로 가기나 메뉴 이동을 통한 조회를 하기 위해 존재함
  created() {
    this.setSearchParam();
    this.doList();
  },
  data() {
    return {
      searchParam: {
        contentNo: '',
        menuId: null,
        postNo: null,
        fieldMenuId: null,
      },
      dataItemList: [],
      imageList: [],
      detailMain: {
        contentNo: null,
        postNo: null,
        termsNo: 0,
        unLimitedYn: 'N',
        nextCloseYn: 'N' ,
        popupTypeCd: null,
        accCd: null,
        dispStDt:null,
        dispEnDt:null,
        useYn: null,
        nextCloseDd:null,
        widthPixel: null,
        heightPixel: null,
        topPixel:'0',
        leftPixel:'0',
        content:'<p></p>',
        noticeYn:'N',
        mainYn: 'N',
        ndisYn: 'N',
        grpCd: null,
        grpNm: null,
        id: null,
        pw: null,
        field01: null,
        field02: null,
        field03: null,
        field04: null,
        field05: null,
        field06: null,
        field07: null,
        field08: null,
        field09: null,
        field10: null,
        field11: null,
        field12: null,
        field13: null,
        field14: null,
        field15: null,
        field16: null,
        field17: null,
        field18: null,
        field19: null,
        field20: null,
        field21: null,
        field22: null,
        field23: null,
        field24: null,
        postSt: null,
        postStDt: null,
        postEnDt: null,
        postEn: null,
        postStTm: null,
        postEnTm: null,
        mainDispStDt: null,
        mainDispEnDt: null,
        mainDispStTm: null,
        mainDispEnTm: null,
        mainTermLimitYn: 'N',
        noticeDispStDt: null,
        noticeDispEnDt: null,
        noticeDispStTm: null,
        noticeDispEnTm: null,
        noticeTermLimitYn: 'N',
        orginPostNo: null,
        requApprYn: 'N',
        selApprYn: 'N',
        attcNo: null,
        delYn: 'N',
        koglCd: null,
      },
      vrCol:'',
      faCol:'',
      fieldList: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        slidesPerGroup: 1,
        pagination: {
          el: '.tinfo_txtw .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1025: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
          },
        },
      },
      isContent: false,
      subContentNo: '',
      subContentType: 'A',
      routeDiv: 'dynamic',
      isLoading: false,
      boardDefaults
    }
  },
  methods: {
    //기본파라미터 세팅
    setSearchParam() {
      this.searchParam.contentNo = this.board.boardNo;
      this.searchParam.fieldMenuId = this.$route.query.menuId;
    },
    doList() {
      this.isLoading = true;
      this.$tran('/site/external/contents/selectPostSglListBdnaE', this.searchParam).then(response => {
        this.dataItemList = response.data.postList

        this.$nextTick(() => {
          const postNo = this.$route.query?.postNo;
          let item = {};

          if(postNo != null && postNo != '') {
            item = this.dataItemList.find(e => e.postNo == postNo);
          }else{ 
            item = this.dataItemList[0];
          }

          this.doDetail(item)
        })
      })
    },
    doDetail(item) {
      this.searchParam.postNo = item.postNo;
      this.$tran('/site/external/contents/selectPostSglDetailBdnaE', this.searchParam).then(response => {
        this.detailMain = response.data.post;
        this.imageList = response.data.imageList;
        this.fieldList = response.data.fieldList;

        this.fieldList.forEach((e) => {
          if(e.fieldData == 'VR'){
            this.vrCol = e.fieldCdNm
          }else if(e.fieldData == 'FA'){
            this.faCol = e.fieldCdNm
          }
        })

        const query = {...this.$route.query};
        query.postNo = this.detailMain.postNo.toString();

        //주소가 같이 않으면주소 변경
        if(JSON.stringify(this.$route.query) != JSON.stringify(query)){
          this.$router.push({query:query});
        }
        }).finally(() => {
          this.isLoading=false
      })
    },
    setTotalGuide() {
      const postNo = this.detailMain.postNo
      this.$router.push({path:'/eng/contents/guide', query:{menuId:'M0032',upMenuId:'M0011',postNo:postNo}})
    },
    doVr() {
      const href = this.detailMain[this.vrCol]
      
      window.open(href , '', 'width=1200px,height=858px,top=0,left=0, scrollbars=yes');
    },
    doFa() {
      const href = this.detailMain[this.faCol]
      const voUrl = this.urlSplit(href)
      if(voUrl != null){
        this.$router.push({path:voUrl.url, query:voUrl.params})
      }
    },
    getGrpIndex() {
      const grpCd = this.detailMain.grpCd;
      if(grpCd == '5464'){
        return 1
      }else if(grpCd == '5465') {
        return 2
      }else if(grpCd == '5466') {
        return 3
      }else if(grpCd == '5467') {
        return 4
      }else {
        return 1
      }
      
    },
    urlSplit(url) {
      if(url == null){
        return null
      }
      const vaUrl = url.split('?');

      const voUrl = {};

      if(vaUrl != null && vaUrl.length > 0){
        voUrl.url = vaUrl[0];
        voUrl.params = {}

        if(vaUrl.length > 1){
          const vaParams = vaUrl[1].split('&');

          for(const idx in vaParams){
            const vaParam = vaParams[idx]
            
            const vaData = vaParam.split("=")

            if(vaData != null && vaData.length > 0){
              if(vaUrl.length > 1){ 
                voUrl.params[vaData[0]] = vaData[1]
              }else{
                voUrl.params[vaData[0]] = null
              }
            }
          }
        }
      }
      return voUrl;
    },
    decodeHTML(str) {
      if(str != null && str != '') {
        return decodeHTMLEntities(str)
      }
      return
    },
    doKeydownDetail(event, item) {
      if(event.keyCode == 13) {
        this.doDetail(item);
      }
    },
    doKeydownTotalGuide(event) {
      if(event.keyCode == 13) {
        this.setTotalGuide();
      }
    },
    doKeydownVr(event) {
      if(event.keyCode == 13) {
        this.doVr();
      }
    },
    doKeydownFa(event) {
      if(event.keyCode == 13) {
        this.doFa();
      }
    }
  },
}
</script>