import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layouts/LayoutMain.vue'
import LayoutEng from '../views_eng/layouts/LayoutMain.vue'
import ipinRet from '../views/ipin/ipinRet.vue'
import { sendPost } from '@/libs/comm/transection'


Vue.use(VueRouter)

const routes = [
  {
    path: '/ipinRet',
    name: 'ipinRet',
    component: ipinRet,
  },
  {
    path: '/404',
    component: () => import('@/views/Error404.vue'),
    name: '404',
    props: true
  },
  {
    path: '/contents/deptSearchPopup',
    component: () => import('@/views/component/deptSearchPopup.vue'),
    name: 'deptSearchPopup',
  },
  {
    path: '/vrTour/view',
    component: () => import('@/views/vrTour/view.vue'),
    name: 'vrTourView',
    props: true,
  },
  {
    path: '/eng/',
    name: 'layoutEng',
    component: LayoutEng,
    redirect: '/eng/intro',
    children: [
      {
        path: '/eng/intro',
        component: () => import('@/views_eng/Main.vue'),
        name: 'introEng'
      },
      {
        path: '/eng/contents/content',
        component: () => import('@/views_eng/contents/mainContent.vue'),
        name: 'contentEng',
        props: true,
      },
      {
        path: '/eng/contents/guide',
        component: () => import('@/views_eng/guide/mapInfo.vue'),
        name: 'guideMapEng',
        props: true,
      },
    ]
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/intro',
    children: [
      {
        path: '/intro',
        component: () => import('@/views/Main.vue'),
        name: 'intro'
      },
      {
        path: '/contents/map',
        component: () => import('@/views/site/siteMap.vue'),
        name: 'siteMap',
        props: true
      },
      {
        path: '/contents/content',
        component: () => import('@/views/contents/mainContent.vue'),
        name: 'content',
        props: true,
      },
      {
        path: '/contents/dynamicDetail',
        component: () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicDetail',
        props: true
      },
      {
        path: '/contents/dynamicDetailExhbit',
        component : () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicDetailExhbit',
        props: true
      },
      {
        path: '/contents/dynamicModify',
        component : () => import('@/views/contents/mainContent.vue'),
        name: 'dynamicModify',
        props: true
      },
      {
        path: '/contents/dept',
        component: () => import('@/views/dept/dept.vue'),
        name: 'dept',
        props: true,
      },
      {
        path: '/contents/totalSearch',
        component: () => import('@/views/search/totalSearch.vue'),
        name: 'totalSearch',
        props: true,
      },
      {
        path: '/contents/guide',
        component: () => import('@/views/guide/mapInfo.vue'),
        name: 'guideMap',
        props: true,
      },
    ]
  },
  {
    path: '*',
    component: () => import('@/views/Error404.vue'),
    name: '404',
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if(to.hash == null || to.hash == ''){
      const voFrom = {...from.query}
      const voTo = {...to.query}
      if(to.path.indexOf('dynamicDetail') < 0){
        delete voFrom.postNo
        delete voTo.postNo
      }
      
      if(JSON.stringify(voFrom) != JSON.stringify(voTo)){
        return { x: 0, y: 0 }
      }
    }
  },
  base: process.env.BASE_URL,
  routes
})

//메뉴 클릭시 화면이 열리기 전에 제어
router.beforeEach((to, from, next) => {  
  const path = to.path
  if(path !='/eng/intro' && path != '/intro'){
    document.querySelector('body').style.overflow = 'auto'
  }
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  sendPost('/site/log/check', {...from});
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
